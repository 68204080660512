<template>
	<div class="row offers mt-2">
		<b-col lg="12">
			<div class="card">
				<b-row align-h="center">
					<b-col lg="8">
						<div class="p-2">
							<div class="mt-2 text-center">Discount Log</div>
							<b-input-group class="mt-3">
								<b-form-select
									id="searchMode"
									v-model="searchMode"
									track-by="value"
									:options="searchModeOptions"
									@change="setSearchMode"
									required
								>
								</b-form-select>
								<b-form-input
									class="ml-2"
									v-model="query_search"
									type="search"
									placeholder="Type to search"
									@change="setQuerySearch"
									trim
								></b-form-input>
								<b-input-group-append>
									<b-button type="submit" variant="primary" @click="asyncFind" class="ml-2">Search</b-button>
									<b-button type="button" variant="dark" class="ml-2" @click="asyncClearFind">Clear</b-button>
									<b-spinner v-show="isLoadingSearch" variant="dark" class="ml-2" label="Loading .."></b-spinner>
								</b-input-group-append>
							</b-input-group>
						</div>
					</b-col>
				</b-row>
				<div class="card-body">
					<b-row class="justify-content-end">
						<span>
							<button class="btn btn-sm btn-dark" @click="actionGetDiscountLog">
								<em class="fa fa-refresh"></em>
							</button>
						</span>
						<label for="" class="col-sm-auto">Count </label>
						<strong class="col-sm-auto">{{ totalRows }}</strong>
					</b-row>
					<b-table
						sticky-header="500px"
						:items="items"
						:fields="fields"
						:busy="isLoading"
						:isLoading="isLoading"
						show-empty
						responsive="xl"
						class="mt-3"
						hover
					>
						<template v-slot:table-busy>
							<div class="text-center text-dark my-2">
								<b-spinner
									class="align-middle"
									variant="dark"
									type="grow"
									label="Loading .."
								>
								</b-spinner>
								Loading ..
							</div>
						</template>
						<template #cell(No)="row">
							{{ (currentPage - 1) * perPage + (row.index + 1) }}
						</template>
						<template #cell(email)="row">
							{{ row.item.email }}
						</template>
						<template #cell(data_url)="row">
							{{ row.item.data_url }}
						</template>
						<template #cell(host_client)="row">
							{{ row.item.host_client }}
						</template>
						<template #cell(method)="row">
							{{ row.item.method }}
						</template>
						<template #cell(created)="row">
							{{ row.item.created }}
						</template>
						<template #cell(action_view)="row">
							<router-link class="btn btn-sm btn-default" :to="{name: DiscountLogDetailPath.name, params: {id: row.item.id}}" target="_blank">
                <em class="fa fa-eye"></em>
              </router-link>
						</template>
					</b-table>
				</div>
				<b-row v-if="(totalRows/perPage) > 1" class="justify-content-start ml-1">
					<b-col lg="3">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							aria-controls="my-table"
						/>
					</b-col>
				</b-row>
			</div>
		</b-col>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { DiscountLogDetailPath } from "../../router/marketing";
export default {
	name: "discount-log",
	data() {
		return {
			DiscountLogDetailPath,
			currentPage: 1,
			perPage: 20,
			query_search: '',
			isLoadingSearch: false,
			searchMode: "1",
			searchModeOptions: [
				{ value: "1", text: "Search Log By Email" },
				{ value: "2", text: "Search Log By Discount Name" },
			],
			fields: [
				{ key: "No", label: "No" },
				{
					key: "email",
					label: "Email",
				},
				{
					key: "data_url",
					label: "Url",
				},
				{
					key: "host_client",
					label: "Host",
				},
				{
					key: "method",
					label: "Method",
				},
				{
					key: "created",
					label: "Created",
				},
				{ key: "action_view", label: "View" },
			],
		};
	},
	computed: {
		...mapState({
			isLoading: (state) => state.discountLog.isLoading,
			items: (state) => state.discountLog.items,
			isError: (state) => state.discountLog.isError,
			totalRows: (state) => state.discountLog.totalRows,
		}),
	},
	mounted() {
		this.actionGetDiscountLog();
	},
	watch: {
		currentPage: function() {
			this.actionGetDiscountLog();
		},
	},
	methods: {
		...mapActions("discountLog", [
			"fetchDiscountLog",
			"searchDiscountLog",
		]),
		actionGetDiscountLog() {
			if (this.query_search == "") {
				let payload = {
					page: this.currentPage,
					limit: this.perPage,
				};
				this.fetchDiscountLog(payload);
			} else {
				this.asyncFind();
			}
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
		setQuerySearch(query) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.query_search = query;
			}, 1000);
		},
		setSearchMode(mode) {
			this.searchMode = mode;
		},
		asyncFind() {
			this.isLoadingSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				const query = this.query_search;
				const modeQuery = this.searchMode;
				if (!query || !modeQuery) {
					this.actionGetDiscountLog();
					this.isLoadingSearch = false;
					return;
				}
				this.searchDiscountLog({
					q: query,
					page: this.currentPage,
					limit: this.perPage,
					mode: modeQuery,
				})
					.then((response) => {
						this.items = response.data.data.rows;
						this.isLoadingSearch = false;
					})
					.catch(() => {
						this.isLoadingSearch = false;
					});
			}, 1000);
		},
		asyncClearFind() {
			this.query_search = "";
			this.searchMode = "1";
			this.actionGetDiscountLog();
			this.isLoadingSearch = false;
		},
	},
};
</script>
